<template>
  <div class="Awarp">
    <dashCard
        class="carbonBox8"
        :operateWidth="operateWidth"
        :isLoading="isLoading"
        :gridOption="gridOption"
        @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('carbonBox8_overview','碳排放总览', getZEdata)}}</template>
      <template slot="aside"> </template>
      <div class="card-content2">
        <div class="type-group">
          <span
              class="pick-type"
              v-for="(item, idx) in timetType"
              :key="idx"
              @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                item.name
              }}</font>
          </span>
        </div>
        <a-month-picker
            v-if="searchObj.TYPE == '按月'"
            :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-date-picker
            v-if="searchObj.TYPE == '按年'"
            :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
            mode="year"
            :open="panelOpen"
            @openChange="openChange"
            @panelChange="panelChange"
            format="YYYY"
        />
        <div style="width: 120px" v-if="searchObj.TYPE == '按季'">
          <el-select
              v-model="value"
              :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)"
              @change="handleChange"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
        v-if="!isModal"
        class="Amodal"
        v-model="option.visible"
        :closable="false"
        :footer="null"
        :keyboard="false"
        width="100%"
        :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox8
            :option="option"
            :isModal="true"
            :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
    <!-- <div><span>同比<font style="color:red;font-size:14px">{{ YOY }}</font></span></div> -->
  </div>
</template>
<script>
export default {
  name: "carbonBox8",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      value: "",
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      searchObj: {},
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      timetType: [
        {
          code: "按月",
          name: this.LangHandler('bymonths','按月'),
        },

        {
          code: "按年",
          name: this.LangHandler('byyears','按年'),
        },
      ],
      YOY: "",
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      options: [
        {
          value: "1",
          label: "第一季度",
        },
        {
          value: "2",
          label: "第二季度",
        },
        {
          value: "3",
          label: "第三季度",
        },
        {
          value: "4",
          label: "第四季度",
        },
      ],
      quarter: false,
      value: "1",
      list: [],
      colorList: ["#0CA919", "#3366FF"],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    handleChange(value) {
      if (!!value) this.value = value;
      this.getData();
    },
    getTimes(value) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      let obj = {
        starttime: "",
        endtime: "",
      };
      if (value == "1") {
        obj.starttime = year + "-01-01 00:00:00";
        obj.endtime = year + "-03-31 23:59:59";
      } else if (value == "2") {
        obj.starttime = year + "-04-01 00:00:00";
        obj.endtime = year + "-06-30 23:59:59";
      } else if (value == "3") {
        obj.starttime = year + "-07-01 00:00:00";
        obj.endtime = year + "-09-30 23:59:59";
      } else if (value == "4") {
        obj.starttime = year + "-10-01 00:00:00";
        obj.endtime = year + "-12-31 23:59:59";
      }
      return obj;
    },
    // 改变类型
    changeType(type) {
      // //console.log(type)
      if (type == "按季") {
        this.handleChange();
      }
      this.searchObj.TYPE = type;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按月: {
          queryType: "month",
          starttime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endtime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        // "按季": {
        //     // type: "month",
        //     starttime: monthVALUE.startOf('month').format('YYYY-MM-DD 00:00:00'),
        //     endtime: monthVALUE.endOf('month').format('YYYY-MM-DD 23:59:59'),
        // },
        按年: {
          queryType: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      // //console.log(obj)
      return obj[TYPE];
    },
    //每月报警预警数
    getData() {
      //console.log(this.searchObj.TYPE);
      this.isLoading = true;
      let url =
          Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/overview";
      let data = {
        code: this.currentSelectDeptInfo.CODE,
        // starttime: "2023-01-01 00:00:00",
        // endtime: "2023-12-31 00:00:00"
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }

      this.$axios
          .post(url, data)
          .then((res) => {
            this.list = res.data.data;
            //console.log(this.list);
            var chart1 = { seriesData: [] };
            // let list1 = []
            // list1 = res.data.data[0]
            // list1.push({title: {offsetCenter: ['0%', '30%']}})
            // //console.log(list1)
            this.detailInfo.chart2 = res.data.data;
            chart1.seriesData = res.data.data.splice(0, 1);
            if (res.data.data[0].value == null) {
              this.YOY = 0;
            } else {
              this.YOY = res.data.data[0].value;
            }

            this.detailInfo.chart1 = chart1;

            this.initEchart();
          })
          .catch((wrong) => {})
          .finally(() => {
            this.isLoading = false;
          });
    },
    initEchart() {
      let vm = this;
      var { seriesData } = this.detailInfo.chart1;

      var sum = seriesData.reduce(function (prev, cur) {
        return prev + cur.value;
      }, 0);
      sum = this.toThousandsSeparator(sum);
      var colorList = this.colorList;
      //console.log(seriesData);
      // seriesData["title"] = {}
      // seriesData["title"].show = false
      const dataArr = 50;
      const dataX = 100;
      var option = {
        animation: false,
        layoutAnimation: false,
        tooltip: {
          formatter: function (params) {
            console.log(params,'------------------',vm.list)
            let str = "";
            str += params.seriesName + "<br>";
            str += params.name + ":" + params.value + "<br>";
            str += vm.LangHandler('targetvalue','目标值') + ":" + vm.list[0].value + "<br>";
            str += vm.LangHandler('yoyrate','同比增长率') + ":" + vm.list[1].value + "<br>";
            return str;
          },
        },
        series: [
          {
            name: "外部指针",
            type: "gauge",
            radius: "75%",
            axisLine: {
              lineStyle: {
                color: [
                  [dataArr / dataX - 0.001, "rgba(0,0,0,0)"],
                  [dataArr / dataX + 0.003, "#C34743"],
                  [1, "rgba(0,0,0,0)"],
                ],
                width: 22,
              },
            },
            axisLabel: {
              show: false,
              formatter: function (params) {
                //格式化函数
                return "目标值";
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              // 标题
              show: true,
              offsetCenter: [0, "-70%"],
              fontSize: "14",
              formatter: function () {
                //格式化函数
                return "目标值";
              },
            },
            data: [
              {
                name: this.LangHandler('targetvalue','目标值'),
                value: dataArr,
              },
            ],
            pointer: {
              show: false,
            },
            animationDuration: 4000,
          },
          {
            //指针上的圆
            type: "pie",
            tooltip: {
              show: false,
            },
            hoverAnimation: false,
            legendHoverLink: false,
            radius: ["0%", "4%"],
            center: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 120,
                itemStyle: {
                  normal: {
                    color: "#d9d9d9",
                  },
                },
              },
            ],
          },
          {
            name: vm.LangHandler('carbonout','碳排放'),
            type: "gauge",
            startAngle: 210,
            endAngle: -30,
            min: 0,
            max: this.detailInfo.chart2[0].value * 2,
            itemStyle: {
              color: "#0CA919",
            },
            progress: {
              show: true,
            },
            splitLine: {
              show: true,
              distance: 0,
              length: 3,
              lineStyle: {
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              formatter: function (params) {
                // let a = params + "\n"+this.LangHandler('total','总计')+"(tCO₂)";
                let a = params + "\n" + vm.LangHandler('total','总计') + '(tCO₂)'
                return a;
              },
              offsetCenter: ["0%", "60%"],
              textStyle: {
                fontSize: 16,
              },
            },
            pointer: {
              show: true,
              length: "70%",
              radius: "20%",
              width: 3, //指针粗细
            },
            title: {
              // 标题
              show: false,
              offsetCenter: [0, "-50%"],
            },
            data: [
              {
                value: seriesData[0].value,
                title: true,
                name: vm.LangHandler('currentvalue','当前值'),
              },
            ],
          },
        ],
      };
      this.$nextTick(() => {
        this.updateChart("chart1", "chartOption1", option);
      });
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox8 {
  .card-content2 {
    display: flex;
  }

  .card-content1 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>